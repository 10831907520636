@import url('https://fonts.googleapis.com/css2?family=AR+One+Sans:wght@400;500;600;700&family=JetBrains+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body,
#root {
	min-width: 100%;
	max-width: 100%;
	height: 100%;
	/* overflow-x: hidden;
overscroll-behavior-y: none; */
	overflow: hidden;
}

/* FONT ----------------------------------- */

:root {
	--font-pixel: 'SF Pixelate', monospace;
	--font-mono: 'monogram', monospace;
}

/* CORE VARS ------------------------------ */

/* APP LAYOUT ----------------------------- */
.App {
	height: 100%;
	width: 100%;
	padding: var(--page-pad);
	overflow: hidden;
	display: flex;
	gap: var(--gap-l);
	background-color: var(--clr-background);
}

/* MAIN ----------------------------------- */
main {
	flex-grow: 4;
	display: flex;
	flex-direction: column;
	gap: var(--gap-s);
}

/* Body */
main > .body {
	overflow-y: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

/* COUNTDOWN PAGE -------------------------- */
#countdown-body {
	max-width: 100vw;
	margin: 5%;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	gap: var(--gap-o);
}

/* BUTTONS -------------------------------- */
button {
	min-height: var(--sp-5);
	padding: var(--gap-i);
	border-style: none;
	border: calc(var(--bdw) / 2) solid var(--clr-primary);
	border-radius: var(--bdr-i);
	background-color: transparent;
	cursor: pointer;
	transition: var(--ix-transition-hover);
}

button:hover {
	background-color: var(--clr-primary);
}

/* INPUT ---------------------------------- */
input {
	padding: var(--gap-s) var(--gap-l);
	margin-top: var(--gap-i);
	border: none;
	border-radius: var(--bdr-min);
	background-color: white;
	box-shadow: var(--ev-neu-1-inset);
	transition: border-radius 0.25s ease;
}

input:focus {
	outline: calc(var(--bdw) / 2) solid var(--clr-ink);
}
