/* BID DIALOG -------------- */
dialog {
	width: 45vw;
	max-width: calc(var(--sp-12) * 3);
	padding: var(--gap-l);
	display: flex;
	flex-direction: column;
	gap: var(--gap-s);
	border-top: var(--bdw) solid var(--clr-ink);
	/* For some insane reason you need to do this for dialog borders? lol... */
	border-right: none;
	border-bottom: none;
	border-left: none;
	/*  */
	border-radius: 0 0 var(--bdr-o) var(--bdr-o);
	background-color: var(--clr-background);
	opacity: 0;
	scale: 0;
	margin: auto;
	transition: 0;
}

dialog[open] {
	opacity: 1;
	scale: 1.5;
	transition: 250ms;
}

/* CURRENT BID ---------------------------- */
#current-bid-container {
	padding: var(--gap-s);
	display: flex;
	justify-content: space-between;
	border: calc(var(--bdw) / 2) solid var(--clr-ink-tr-light);
	border-radius: var(--bdr-min);
	font-size: 0.675rem;
}

#current-bid-container > * {
	font-family: var(--font-display);
}

/* ACTIONS -------------------------------- */
.action-row {
	display: flex;
	gap: var(--gap-s);
}

.action-row > * {
	flex-grow: 1;
	font-size: 0.75rem;
}
