.host {
	height: var(--nav-height);
	display: flex;
	gap: var(--gap-s);
}

.host > * {
	height: 100%;
	display: flex;
	gap: var(--gap-s);
}

@media only screen and (max-width: 960px) {
	.host {
		flex-direction: column;
		height: fit-content;
	}

	.host > * {
		flex-grow: 1;
	}

	.host > * > * {
		flex-grow: 1;
	}
}

/* BRAND ---------------------------------- */
#brand-container {
	padding: var(--gap-l) 0;
	flex-grow: 1;
	border-top: var(--bdw) solid var(--clr-ink);
}

@media only screen and (max-width: 960px) {
	#brand-container {
		max-height: var(--sp-9);
	}
}

/* NAV ------------------------------------ */
@media only screen and (max-width: 960px) {
	#nav {
		max-height: var(--sp-9);
	}
}

#nav > * {
	padding: var(--gap-l);
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: var(--bdw) solid var(--clr-ink);
	border-radius: 0 0 var(--bdr-o) var(--bdr-o);
	transition: var(--ix-transition-hover);
}

@media only screen and (max-width: 960px) {
	#nav > * {
		flex: 1 1 0;
	}
}

#nav > *:hover {
	background-color: var(--clr-surface);
}

#nav > * > * {
	transition: var(--ix-transition-hover);
}

#nav > *:hover > * {
	transform: var(--ix-transform-hover);
}

/* LINKS ---------------------------------- */
#links-container > * {
	width: var(--sp-5);
	padding: var(--gap-min);
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: var(--bdw) solid var(--clr-ink);
	border-radius: 0 0 var(--bdr-o) var(--bdr-o);
	transition: var(--ix-transition-hover);
}

@media only screen and (max-width: 960px) {
	#links-container {
		max-height: var(--sp-5);
	}
}

#links-container > *:hover {
	background-color: var(--clr-surface);
}

#links-container > * > * {
	max-height: 100%;
	transition: var(--ix-transition-hover);
}

#links-container > * > *:hover {
	transform: var(--ix-transform-hover);
}

#link-x,
#link-opensea {
	padding: var(--gap-s);
}
