/* CONNECT BUTTON ------------------------ */
.host {
}

@media screen and (min-width: 1921px) {
	#connect-button-container {
		height: var(--sp-12);
	}
}

#connect-button {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: var(--clr-surface-secondary-gr);
	transition: 150ms;
	cursor: pointer;
}

#connect-button span {
	font-size: 1.25rem;
	transition: 300ms;
}

#connect-button:hover span {
	color: var(--clr-accent-secondary);
}
