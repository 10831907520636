/* HOST ----------------------------------- */
#auction-container {
	flex-grow: 1;
	overflow-y: auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: var(--gap-l);
}

@media only screen and (min-width: 1080px) and (max-width: 1200px) {
	#auction-container {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media only screen and (max-width: 1080px) {
	#auction-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media only screen and (max-width: 680px) {
	#auction-container {
		grid-template-columns: repeat(1, 1fr);
	}
}

/* PLACEHOLDER ---------------------------- */
#placeholder-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	background-color: var(--clr-background);
	z-index: 8;
}

#placeholder-overlay .body {
	width: 50%;
	height: 50%;
	background-image: url('/public/overlay-hold.webp');
	background-position: center;
	background-size: 100%;
	background-repeat: no-repeat;
	animation-name: bg-pulse;
	animation-duration: 600ms;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

@keyframes bg-pulse {
	from {
		background-size: 75%;
	}
	to {
		background-size: 100%;
	}
}

[data-prelaunch='false']#placeholder-overlay {
	animation-name: placeholder;
	animation-duration: 600ms;
	animation-delay: 1600ms;
	animation-iteration-count: once;
	animation-fill-mode: forwards;
}

@keyframes placeholder {
	0% {
		opacity: 1;
		z-index: 8;
	}
	99% {
		opacity: 0;
		z-index: 8;
	}
	100% {
		opacity: 0;
		display: none !important;
		z-index: -1;
	}
}

/* COUNTER -------------------------------- */
#placeholder-counter-container {
	padding: var(--gap-l) var(--sp-9);
	display: flex;
	justify-content: center;
	align-items: center;
	/* border: var(--bdw) solid var(--clr-ink); */
	border-radius: var(--bdr-o);
	background-color: var(--clr-primary-tr-light-x);
	backdrop-filter: blur(var(--sp-4));
	z-index: 12;
}

[data-prelaunch='false'] #placeholder-counter-container {
	display: none;
}

#placeholder-counter-container > span {
	font-size: 3rem;
}
