/* HOST ----------------------------------- */
.gallery-modal-tile-container {
	max-height: fit-content;
	padding-top: var(--gap-l);
	grid-column: span 1;
	display: flex;
	gap: var(--gap-l);
	border-top: var(--bdw) solid var(--clr-ink);
}

@media screen and (max-width: 720px) {
	.gallery-modal-tile-container {
		flex-direction: column;
	}
}

/* IMAGE ---------------------------------- */
.thumb {
	max-width: calc(var(--sp-12) * 2);
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--sp-1);
	box-shadow: var(--ev-neu-1-inset);
}

@media screen and (max-width: 720px) {
	.thumb {
		max-width: 100%;
		max-height: calc(var(--sp-12) * 4);
	}
}

.thumb img {
	width: 120%;
}

.thumb[data-is-ready='false']::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.thumb[data-is-ready='false']::after {
	content: '';
	position: absolute;
	width: var(--sp-5);
	height: var(--sp-5);
	border: var(--bdw-w) solid var(--clr-background);
	border-radius: 50%;
	animation-name: loading-pulse;
	animation-duration: 300ms;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	opacity: 0;
}

@keyframes loading-pulse {
	from {
		transform: scale(0.5);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

.thumb[data-is-ready='true']::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--clr-ink);
	animation-name: loading-reveal;
	animation-duration: 600ms;
	animation-fill-mode: forwards;
}

@keyframes loading-reveal {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

/* BODY ----------------------------------- */
.body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

/* ROWS ----------------------------------- */
h3 {
	text-align: start;
	margin-bottom: var(--gap-l);
}

.row {
	/* height: var(--sp-5); */
	padding: var(--gap-s) 0;
	display: flex;
	justify-content: space-between;
	border-top: var(--bdw) solid var(--clr-ink-tr-light);
}

.row span {
	font-family: var(--font-display);
}

.row span:first-child {
	color: var(--clr-ink-tr-heavy);
	font-weight: 600;
}

@media screen and (max-width: 720px) {
	.row {
		max-height: fit-content;
		flex-direction: column;
		align-items: start;
		gap: var(--gap-ii);
	}
}

.aux {
	/* min-height: var(--sp-5); */
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: var(--gap-l);
}

.aux > a {
	height: var(--sp-5);
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--bdr-min);
	border: calc(var(--bdw) / 2) solid var(--clr-ink-tr-light);
}

.aux > a > svg path {
	fill: var(--clr-ink);
}

.aux > a > svg {
	transition: var(--ix-transition-hover);
}

.aux > a:hover > svg {
	transform: scale(1.125);
}
