/* HOST ----------------------------------- */
.host {
	padding-top: var(--gap-l);
	position: relative;
	grid-column: span 1;
	display: flex;
	gap: var(--gap-s);
	flex-direction: column;
	border-top: var(--bdw) solid var(--clr-ink-tr-light);
	transition: var(--ix-transition-hover);
	cursor: pointer;
	min-height: 40vh;
}

@media only screen and (max-width: 1080px) {
	.host {
		min-height: 67.5vh;
	}
}

.host:hover {
	border-color: var(--clr-ink);
}

/* THUMBNAIL ------------------------------ */
.thumbnail-container {
	position: relative;
	overflow: hidden;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	border-radius: var(--bdr-o);
	transition: 300ms;
}

.thumbnail {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-position: center;
	transition: 300ms;
}

.thumbnail:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: var(--bdr-o);
	background-color: transparent;
	backdrop-filter: blur(0);
	transition: var(--ix-transition-hover);
}

.thumbnail:hover:before {
	background-color: var(--clr-primary-tr-light);
	backdrop-filter: blur(var(--sp-1));
}

.host:hover .thumbnail {
	transform: scale(1.12);
}

.host:hover .thumbnail::after {
	opacity: 0.5;
}

/* RING GENERATOR ------------------------- */
.ring-generator {
	width: var(--sp-1);
	height: var(--sp-1);
	position: absolute;
	border-radius: 50%;
	outline: 1px solid var(--clr-background);
	outline-offset: 0px;
	opacity: 0;
	transition: var(--ix-transition-hover);
}

.ring-generator:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	outline: 1px solid var(--clr-background);
	outline-offset: 0px;
	opacity: 0;
	transition: var(--ix-transition-hover);
}

.thumbnail:hover .ring-generator {
	z-index: 2;
	animation-name: ring-pulse;
	animation-duration: 1200ms;
	animation-iteration-count: infinite;
}

.thumbnail:hover .ring-generator:before {
	animation-name: ring-pulse;
	animation-duration: 1200ms;
	animation-iteration-count: infinite;
	animation-delay: 120ms;
}

@keyframes ring-pulse {
	0% {
		opacity: 0;
		outline-offset: 0px;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		outline-offset: 12.5vw;
	}
}

/* OVERLAY ACTION ------------------------- */
.overlay-action {
	padding: var(--gap-s) var(--gap-l);
	border: 1px solid var(--clr-surface);
	border-radius: var(--bdr-i);
	opacity: 0;
	transition: var(--ix-transition-hover);
	z-index: 4;
}

.thumbnail:hover .overlay-action {
	opacity: 1;
}

.overlay-action span {
	font-family: var(--font-display);
	color: var(--clr-surface);
}

/* DETAILS -------------------------------- */
.details-container {
	position: absolute;
	bottom: 0;
	display: flex;
}

.details-container svg * {
	fill: var(--clr-background);
}

.details-container {
	transform: translateY(1px);
}

.details-container > *:first-child {
	transform: translateX(2px);
}

.details-container > *:last-child {
	transform: translateX(-2px);
}

@media only screen and (min-width: 1200px) {
	.host:nth-child(n + 5) .details-container {
		flex-direction: row-reverse;
		top: 0;
		bottom: auto;
		transform: translateY(-1px);
	}

	.host:nth-child(n + 5) .details-container > *:first-child {
		transform: translateX(-2px) rotate(180deg) !important;
	}

	.host:nth-child(n + 5) .details-container > *:last-child {
		transform: translateX(2px) rotate(180deg) !important;
	}
}

.details {
	padding: 0 var(--gap-min);
	flex-grow: 1;
	background-color: var(--clr-background);
}

/* WINNING STRING ------------------------- */
[data-is-winning='true'] .user-winning-string-container {
	height: var(--sp-4);
	position: absolute;
	top: var(--gap-l);
	left: var(--gap-l);
	right: var(--gap-l);
	display: flex;
	justify-content: center;
	align-items: center;
	border: calc(var(--bdw) / 2) solid var(--clr-ink-on-heavy);
	border-radius: var(--bdr-i);
	background-color: var(--clr-primary-tr-light);
	backdrop-filter: blur(var(--sp-1));
	transition: var(--ix-transition-hover);
	z-index: 4;
}

[data-is-winning='true']:hover .user-winning-string-container {
	top: var(--gap-max);
}

.user-winning-string-container > span {
	color: var(--clr-ink-on-heavy);
	font-weight: 600;
	font-size: 0.75rem;
}
