/* HOST ----------------------------------- */
#side-panel {
	--panel-w: 400px;
	width: var(--panel-w);
	padding: var(--gap-l);
	overflow-x: hidden;
	overflow-y: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	gap: var(--gap-l);
	border-top: var(--bdw) solid var(--clr-ink);
	border-radius: 0 0 var(--bdr-o) var(--bdr-o);
	background-color: var(--clr-surface);
	transition: 300ms;
	transition-timing-function: ease-in-out;
	z-index: 6;
}

@media screen and (min-width: 1920px) {
	#side-panel {
		--panel-w: 600px;
	}
}

@media screen and (max-width: 960px) {
	#side-panel {
		position: fixed;
		top: var(--page-pad);
		right: 0;
		bottom: var(--page-pad);
		/*  */
		/* This property needs to be toggled on via prop/data attribute etc for the panel reveal mechanism */
		/*  */
		transform: translateX(var(--panel-w));
	}

	#sidepanel[data-is-visible='true'] {
		right: 400px;
	}
}

/* HIDE BUTTON----------------------------- */
#hide-button {
	padding: 0 var(--gap-l);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media screen and (min-width: 961px) {
	#hide-button {
		display: none;
	}
}

/* FOCUS TOKEN CONTAINER ------------------ */
#focus-token-container {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: var(--gap-l);
}

/* IMAGE ---------------------------------- */
#focus-token-image-container {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	flex-start: start;
	border-radius: var(--bdr-i);
	background-image: var(--bg-url);
	background-size: cover;
	background-position: center;
}

/* TITLE ---------------------------------- */
#focus-token-title-container {
	display: flex;
	height: var(--sp-3);
	transform: translateY(-1px);
}

#focus-token-title-container > *:first-child {
	transform: rotate(180deg) translateX(-1px);
}

#focus-token-title-container > *:first-child > path {
	fill: var(--clr-surface);
}

#focus-token-title-container > *:last-child {
	transform: rotate(180deg) translateX(1px);
}

#focus-token-title-container > *:last-child > path {
	fill: var(--clr-surface);
}

#focus-token-title-container > .title {
	padding: 0 var(--gap-l);
	background-color: var(--clr-surface);
}

#focus-token-title-container > .title > span {
	font-family: var(--font-display);
	text-transform: var(--font-case-display);
	font-weight: 600;
	font-size: 0.875rem;
}

/* DETAILS -------------------------------- */
#focus-token-auction-details-container {
	display: flex;
	flex-direction: column;
}

#focus-token-auction-details-container > .item {
	height: var(--sp-5);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: var(--bdw) solid var(--clr-ink);
}

#focus-token-auction-details-container > .item > span {
	font-family: var(--font-display);
}

#focus-token-auction-details-container > .item > span:first-child {
	font-weight: 600;
}

#focus-token-auction-details-container > .item > span:last-child {
	color: var(--clr-ink-tr-x);
	font-weight: 400;
}
