/* HOST ----------------------------------- */
#gallery-button-container {
	cursor: pointer;
}

#gallery-button {
	font-family: var(--font-display);
	font-weight: 700;
	text-transform: var(--font-case-display);
}

/* MODAL ---------------------------------- */
#gallery-modal-container {
	padding: var(--gap-l);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	background-color: var(--clr-background);
	z-index: 10;
	transition: var(--ix-transition-hover);
}

#gallery-modal-container[data-is-visible='false'] {
	opacity: 0;
	transform: translateY(100%);
}

#gallery-modal-container[data-is-visible='true'] {
	opacity: 1;
	transform: translateY(0);
}

/* HEADER --------------------------------- */
.header {
	/* width: 100%; */
	height: var(--nav-height);
	min-height: var(--sp-9);
	padding: var(--gap-i) var(--gap-o);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: var(--bdw) solid var(--clr-ink);
}

/* ACTIONS -------------------------------- */
.action-container {
	height: 100%;
	display: flex;
	gap: var(--gap-l);
	align-items: center;
}

#action-close {
	width: var(--sp-12);
}

#action-sort {
	padding: 0 var(--gap-l);
}

#action-sort span {
	opacity: 0.75;
}

/* BODY ----------------------------------- */
.body {
	overflow-y: auto;
	flex-grow: 1;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-content: start;
	align-items: start;
	gap: var(--gap-max);
}

@media screen and (min-width: 1921px) {
	.body {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 1080px) {
	.body {
		grid-template-columns: repeat(1, 1fr);
	}
}

/* LOAD MORE BUTTON ----------------------- */
#load-more-button {
	width: auto;
	margin: 10px auto;
	padding: 10px 20px;
	height: var(--sp-4);
	border: calc(var(--bdw) / 2) solid var(--clr-ink-tr-light);
	font-size: 0.675rem;
	color: var(--clr-ink);
	cursor: pointer;
	justify-self: center;
	align-self: center;
	grid-column: 1 / span 2;
	transition: var(--ix-transition-hover);
}

@media screen and (min-width: 1921px) {
	#load-more-button {
		grid-column: 1 / span 3;
	}
}

@media screen and (max-width: 1080px) {
	#load-more-button {
		grid-column: 1 / span 1;
	}
}

#load-more-button:hover {
	background-color: var(--clr-ink);
	color: var(--clr-ink-on-heavy);
}

/* IS LOADING STRING --------------------- */
#is-loading-string {
	position: absolute;
	top: 48%;
	left: 46%;
	font-family: var(--font-display);
	text-transform: var(--font-case-display);
}
